var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Grid",
        {
          ref: "grid",
          attrs: {
            "data-items": _vm.result,
            filterable: true,
            filter: _vm.filter,
            "edit-field": "inEdit",
            pageable: _vm.pageable,
            take: _vm.take,
            skip: _vm.skip,
            "page-size": _vm.pageSize,
            total: _vm.total,
            sortable: { mode: "multiple" },
            sort: _vm.sort,
            columns: _vm.columns,
            "selected-field": _vm.selectedField,
          },
          on: {
            filterchange: _vm.filterChange,
            sortchange: _vm.sortChangeHandler,
            itemchange: _vm.itemChange,
            dataStateChange: _vm.dataStateChange,
            pagechange: _vm.pageChange,
            rowclick: _vm.onRowClick,
          },
          scopedSlots: _vm._u([
            {
              key: "myTemplate",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("custom", {
                    attrs: { "data-item": props.dataItem },
                    on: {
                      edit: _vm.edit,
                      save: _vm.save,
                      remove: _vm.remove,
                      cancel: _vm.cancel,
                    },
                  }),
                ]
              },
            },
            {
              key: "myDropDownCell",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("ddcell", {
                    attrs: { "data-item": props.dataItem, field: props.field },
                    on: {
                      change: function (e) {
                        return _vm.ddChange(e, props.dataItem)
                      },
                    },
                  }),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "grid-toolbar",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "flex-start",
                "align-items": "center",
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.user.claims.user_role == "super_user" ||
                            _vm.user.claims.user_role == "customer_admin" ||
                            _vm.user.claims.user_role == "customer_edit",
                          expression:
                            "\n            user.claims.user_role == 'super_user' ||\n            user.claims.user_role == 'customer_admin' ||\n            user.claims.user_role == 'customer_edit'\n          ",
                        },
                      ],
                      staticClass:
                        "font-weight-bold text-xs btn-default bg-gradient-default",
                      attrs: { ripple: false, elevation: 0, id: "new_button" },
                      on: { click: _vm.insert },
                    },
                    [_vm._v(" Add new ")]
                  ),
                  _vm._v("  "),
                  _c(
                    "v-btn",
                    {
                      attrs: { id: "export_excel" },
                      on: { click: _vm.exportExcel },
                    },
                    [_vm._v(" Export Data ")]
                  ),
                  _vm._v("  "),
                  _c(
                    "v-btn",
                    {
                      attrs: { id: "export_excel" },
                      on: { click: _vm.showColumnModal },
                    },
                    [_vm._v("Select Fields")]
                  ),
                  _vm.hasItemsInEdit
                    ? _c(
                        "v-btn",
                        {
                          staticClass:
                            "font-weight-bold text-xs btn-default bg-gradient-danger",
                          attrs: { ripple: false, elevation: 0 },
                          on: { click: _vm.cancelChanges },
                        },
                        [_vm._v(" Cancel current changes ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-btn-toggle",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.user.claims.user_role == "super_user",
                      expression: "user.claims.user_role == 'super_user'",
                    },
                  ],
                  staticStyle: { "margin-left": "auto" },
                  attrs: { id: "btn_toolbar" },
                  model: {
                    value: _vm.allToggle,
                    callback: function ($$v) {
                      _vm.allToggle = $$v
                    },
                    expression: "allToggle",
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.allDisabled,
                        value: "left",
                        ripple: _vm.high,
                        elevation: _vm.high ? 1 : 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.viewAll()
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "hidden-sm-and-down" }, [
                        _vm._v("All"),
                      ]),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.sunDisabled,
                        value: "right",
                        ripple: _vm.high,
                        elevation: _vm.high ? 1 : 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.viewSunovision()
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "hidden-sm-and-down" }, [
                        _vm._v("Sunovision Managed"),
                      ]),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.customDisabled,
                        value: "justify",
                        ripple: _vm.high,
                        elevation: _vm.high ? 1 : 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.viewCustomer()
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "hidden-sm-and-down" }, [
                        _vm._v("Customer Managed"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("grid-norecords", [_vm._v("There is no data available custom ")]),
        ],
        1
      ),
      _c("new-case-modal", {
        attrs: { show_switch: _vm.visibleDialog, edit_item: _vm.edit_item },
        on: {
          save: _vm.save,
          saveAndClose: _vm.saveAndClose,
          cancel: _vm.handleCancel,
          close: _vm.handleClose,
          remove: _vm.remove,
        },
      }),
      _c(
        "v-dialog",
        {
          attrs: { width: "300" },
          model: {
            value: _vm.columnDialog,
            callback: function ($$v) {
              _vm.columnDialog = $$v
            },
            expression: "columnDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 grey lighten-2" },
                [
                  _vm._v(" Select Columns "),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.columnDialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                _vm._l(_vm.case_fields, function (f) {
                  return _c(
                    "div",
                    [
                      _c("v-checkbox", {
                        attrs: { label: "" + f.title, value: f },
                        model: {
                          value: _vm.selected_fields,
                          callback: function ($$v) {
                            _vm.selected_fields = $$v
                          },
                          expression: "selected_fields",
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("v-btn", { on: { click: _vm.saveColumns } }, [
                    _vm._v(" Save Columns "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }