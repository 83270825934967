<template>
  <td v-if="!dataItem.inEdit" :class="className">
    {{ dataItem.contact ? `${dataItem.contact.name}` : "" }}
  </td>
  <td v-else>
    <!-- <dropdownlist
      :data-items="contacts"
      :default-item="defaultItem"
      :text-field="'name'"
      :data-item-key="'id'"
      :value="value"
      @change="change"
    >
    </dropdownlist> -->
    <combobox
        :data-items="contacts"
        :text-field="'name'"
        :data-item-key="'id'"
        :value="value"
        @change="change"
        :allow-custom="true"
    ></combobox>
    <!-- <combobox
      :data-items="contacts"
      :default-item="defaultItem"
      :text-field="'name'"
      :data-item-key="'id'"
      :value="value"
      @change="change"
      :allow-custom="true"
    /> -->
  </td>
</template>
<script>
import Service from "@/services/Service.js";

import { ComboBox } from '@progress/kendo-vue-dropdowns';
export default {
  components: {
    combobox: ComboBox
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  data: function () {
    return {
      contacts: [],
      value: {},
      defaultItem: {
        name: "Select contact ...",
        id: 0,
      },
    };
  },
  created: function () {
    // lookup up contacts
    console.log(`dataItem: ${this.dataItem}, field: ${this.field}`);
    if(this.dataItem.contact) {
      this.value = {id: this.dataItem.contact.id, name: this.dataItem.contact.name}
    }
    // console.log("ContactDropDownCell created...");
    this.getData();
  },
  methods: {
    getData: function () {
      Service.getContacts()
        .then((response) => {
          // console.log("getData call... set gridData");
          this.contacts = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    change(e) {
      // handle new value
      if(e.target.value.id == undefined) {
        Service.createContact({name: e.target.value.name})
        .then((response) => {
          // console.log("getData call... set gridData");
          const contact = response.data;
          this.value = {id: contact.id, name: contact.name};
          e.target.value.id = contact.id;
          this.$emit("change", e, contact);
        })
        .catch((error) => {
          console.log(error);
        });
      } else {
        this.value = {id: e.target.value.id, name: e.target.value.name};
        this.$emit("change", e, e.target.value);
      }
    },
  },
};
</script>