var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.dataItem["inEdit"]
    ? _c("td", [
        _c(
          "button",
          {
            staticClass: "k-primary k-button k-grid-edit-command",
            on: { click: _vm.editHandler },
          },
          [_vm._v(" Edit ")]
        ),
        _c(
          "button",
          {
            staticClass: "k-button k-grid-remove-command",
            on: { click: _vm.removeHandler },
          },
          [_vm._v(" Remove ")]
        ),
      ])
    : _c("td", [
        _c(
          "button",
          {
            staticClass: "k-button k-grid-save-command",
            on: { click: _vm.addUpdateHandler },
          },
          [
            _vm._v(
              " " + _vm._s(_vm.dataItem.ProductID ? "Update" : "Add") + " "
            ),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "k-button k-grid-cancel-command",
            on: { click: _vm.cancelDiscardHandler },
          },
          [
            _vm._v(
              " " + _vm._s(_vm.dataItem.ProductID ? "Cancel" : "Discard") + " "
            ),
          ]
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }